<template>
  <v-container fluid class="pa-0">
    <v-tabs
      background-color="#454545"
      slider-color="white"
      color="white"
      centered
      dark
      slider-size="4"
      height="55px"
    >
      <v-tab> 전체 제품 </v-tab>
      <v-tab-item>
        <!-- 랜딩 이미지 들어갈 곳 -->
        <v-row class="mt-0 mb-0">
          <v-img :src="require('@/assets/images/ProductPage/MainTop.png')">
            <v-row>
              <v-card flat class="transparent mt-10 mx-auto">
                <v-card-title
                  class="white--text font-weight-bold mt-4"
                  :class="
                    $vuetify.breakpoint.mdAndUp ? 'display-2' : 'headline'
                  "
                >
                  MADEALL 3D Printer
                </v-card-title>
              </v-card>
            </v-row>
          </v-img>
        </v-row>

        <!-- 랜딩 이미지 하단 멘트 들어가는 곳 -->
        <v-row id="document">
          <v-card class="mt-8 transparent mx-auto text-center" flat>
            <v-card-title
              class="justify-center mb-5 font-weight-bold"
              :class="$vuetify.breakpoint.mdAndUp ? 'headline' : 'title'"
            >
              상상을 현실로 만드는 메이드올 3D프린터
            </v-card-title>
            <v-card-subtitle
              class="mb-10 black--text"
              :class="
                $vuetify.breakpoint.mdAndUp ? 'subtitle-1' : 'body-1 px-10'
              "
            >
              메이드올의 3D프린터는 교육용, 실습용, 산업용 등 다양한 용도로
              사용하실 수 있습니다.<br />
              깔끔한 디자인과 견고한 플랫폼에서 오는 기술력의 차이를 지금 바로
              만나보세요.
            </v-card-subtitle>
          </v-card>
        </v-row>

        <!-- 전체 제품 카드 리스트 들어가는 곳 -->
        <v-container
          fluid
          style="background-color: #f5f5f5"
          class="mt-5 pb-15 pt-15"
        >
          <v-container
            class="mb-5"
            v-for="(item, i) in productData"
            :key="i"
            style="max-width: 930px"
          >
            <v-row class="mt-5">
              <v-col
                v-if="i % 2 != 1 && $vuetify.breakpoint.mdAndUp"
                cols="12"
                md="6"
                class="pa-0"
              >
                <v-img :src="productData[i].img" height="400px"></v-img>
              </v-col>
              <v-col
                v-else-if="$vuetify.breakpoint.mdAndDown"
                cols="12"
                md="6"
                class="pa-0"
              >
                <v-img :src="productData[i].img" height="400px"></v-img>
              </v-col>
              <v-col
                cols="12"
                md="6"
                class="pa-7"
                style="background-color: #ffffff"
              >
                <v-row class="ml-5">
                  <v-card flat style="transparent">
                    <v-card-title
                      class="mt-3 mb-5 headline font-weight-bold"
                      v-text="productData[i].title"
                    />
                    <v-card-subtitle
                      class="mb-0 title black--text"
                      v-text="productData[i].subtitle"
                    />
                    <v-card-text
                      class="subtitle-1"
                      v-html="productData[i].content"
                    />
                    <v-row class="pa-2">
                      <v-col cols="5" class="text-center">
                        <v-col cols="12" class="pa-1"> 가격 </v-col>
                        <v-col cols="12" class="pa-0">
                          {{ productData[i].price }}
                        </v-col>
                      </v-col>
                      <v-divider vertical class="my-3" />
                      <v-col cols="7" class="text-center">
                        <v-col cols="12" class="pa-1"> 출력 사이즈 </v-col>
                        <v-col cols="12" class="pa-0">
                          {{ productData[i].size }}
                        </v-col>
                      </v-col>
                    </v-row>
                    <v-card-actions>
                      <v-btn
                        color="primary"
                        class="font-weight-bold mt-2 mx-auto px-4"
                        medium
                        target="_blank"
                        :href="productData[i].link"
                        depressed
                      >
                        더 알아보기
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-container>
      </v-tab-item>
      <!-- <v-tab> MA3D:Mini </v-tab>
      <v-tab-item>
        <div class="text-center ma-10 headline">준비중입니다.</div>
      </v-tab-item>
      <v-tab> MA3D:Stadard </v-tab>
      <v-tab-item>
        <div class="text-center ma-10 headline">준비중입니다.</div>
      </v-tab-item>
      <v-tab> MA3D:Pro </v-tab>
      <v-tab-item>
        <div class="text-center ma-10 headline">준비중입니다.</div>
      </v-tab-item> -->
    </v-tabs>
  </v-container>
</template>

<script>
import setMeta from "@/utils/setMeta";

export default {
  components: {},
  name: "Products",
  data: () => ({
    productData: [
      {
        img: require("@/assets/images/ProductPage/Mini.png"),
        title: "MA3D:Mini",
        subtitle: "컴팩트한 사이즈, 깔끔한 디자인",
        content:
          "듀얼노즐로 두가지 소재를 동시에!<br/>" +
          "컴팩트한 사이즈로 휴대가 가능한 교육용 프린터<br/>" +
          "싱글노즐의 한계를 뛰어넘은 다양한 교육",
        price: "1,800,000원",
        size: "120*120*150(mm)",
        link: "https://smartstore.naver.com/madeall/products/4828115943",
      },
      {
        img: require("@/assets/images/ProductPage/Standard.png"),
        title: "MA3D:Standard",
        subtitle: "향상된 품질, 뛰어난 신뢰성",
        content:
          "고속 압출, 고속 정밀 이동<br/>" +
          "밀폐형 구조의 안정성과 다양한 소재 지원<br/>" +
          "높은 생산성의 준대형 산업용 프린터",
        price: "2,870,000원",
        size: "310*310*350(mm)",
        link: "https://smartstore.naver.com/madeall/products/755355535",
      },
    ],
  }),
  beforeCreate() {
    setMeta({
      title: "제품 소개",
      description: "메이드올의 제품을 한눈에",
      image:
        "https://firebasestorage.googleapis.com/v0/b/madeall.appspot.com/o/Assets%2FOG%2Fmain.png?alt=media&token=d87cafdc-d36d-428c-bc9d-44d66386d74b",
    });
  },
  methods: {},
};
</script>

<style></style>
